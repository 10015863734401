<template>
  <div>
    <b-modal
      id="modal-prolongations-insured-amount"
      ref="prolongationsInsuredAmount"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      title="Accepteer prolongatie"
      :hide-footer="true"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(setProlongationsInsuredAmount)"
        >
          <validation-provider
            #default="validationContext"
            name="insuredAmount"
            rules="required|decimal"
          >
            <b-form-group>
              <label>Verzekerd bedrag / Jaarloon</label>
              <b-form-input
                id="insuredAmount"
                v-model="insuredAmount"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                type="number"
                step=".01"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner
              v-if="prolongationsInsuredAmountSpinner"
              small
              class="mr-1"
            />
            Opslaan
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-tabs
      v-model="tabIndex"
      lazy
    >
      <b-tab
        ref="tabProlongationActionRequert"
        title="Actie vereist"
        content-class="mt-1"
        show
        lazy
        @click="updateStatus('1')"
      >
        <b-overlay
          :show="showOverlay"
          rounded="sm"
        >
          <template>
            <b-row>
              <b-col
                cols="12"
                xl="12"
                lg="12"
                md="12"
              >
                <template>
                  <!-- Table Container Card -->
                  <b-card
                    no-body
                  >
                    <div class="m-2">
                      <!-- Table Top -->
                      <b-row>
                        <!-- Per Page -->
                        <b-col
                          cols="12"
                          md="6"
                          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                        >
                          <label>Entries</label>
                          <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block ml-50 mr-1"
                          />
                        </b-col>
                        <!-- Add -->
                        <b-col
                          class="d-flex align-items-center justify-content-end"
                          cols="12"
                          md="6"
                        />
                      </b-row>
                    </div>
                    <b-table
                      ref="refProlongationsListTable"
                      :items="fetchProlongations"
                      responsive
                      :fields="tableColumns"
                      primary-key="id"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="isSortDirDesc"
                      show-empty
                      empty-text="Geen overeenkomende records gevonden"
                      class="position-relative prolongations-table"
                    >
                      <!-- Column: Id -->
                      <template #cell(id)="data">
                        #{{ data.item.id }}
                      </template>
                      <template #cell(orderID)="data">
                        <b-link
                          :id="`order-detail-view-action-row-${data.item.id}`"
                          :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                        >
                          #{{ data.value }}
                        </b-link>
                      </template>
                      <template #cell(product)="data">
                        <div vertical-align="center">
                          <span class="font-weight-bold d-block text-nowrap">
                            {{ data.item.order.product.name }}
                          </span>
                          <small class="text-muted">{{ formatDateTime(data.item.order.timestamp) }}</small>
                        </div>
                      </template>
                      <template #cell(company)="data">
                        <div vertical-align="center">
                          <span class="font-weight-bold d-block text-nowrap">
                            {{ data.item.order.user.company.name }}
                          </span>
                          <small class="text-muted">{{ data.item.order.user.fullname }}</small>
                        </div>
                      </template>
                      <template #cell(premium)="data">
                        {{ data.value }} {{ data.item.order.currency }}
                      </template>
                      <template #cell(insuredAmount)="data">
                        {{ data.value }} {{ data.item.order.currency }}
                      </template>
                      <template #cell(timestamp)="data">
                        <div vertical-align="center">
                          <span class="font-weight-bold d-block text-nowrap">
                            {{ formatDateTime(data.value) }}
                          </span>
                          <small class="text-muted">Jaar {{ data.item.yearFrom }} tot {{ data.item.yearTo }}</small>
                        </div>
                      </template>
                      <!-- Column: Actions -->
                      <template #cell(actions)="data">
                        <div class="text-nowrap">
                          <b-link>
                            <feather-icon
                              :id="`prolongation-row-${data.item.id}-update-icon`"
                              class="cursor-pointer"
                              size="16"
                              icon="SendIcon"
                              @click="openProlongationsInsuredAmountModal(data.item.id)"
                            />
                          </b-link>
                          <b-tooltip
                            :target="`prolongation-row-${data.item.id}-update-icon`"
                            placement="top"
                          >
                            <p class="mb-0">
                              Verzekerd bedrag of Jaarloon doorgeven
                            </p>
                          </b-tooltip>
                        </div>
                      </template>
                    </b-table>
                    <div class="mx-2 mb-2">
                      <b-row>

                        <b-col
                          cols="12"
                          sm="6"
                          class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                          <span class="text-muted">Records {{ dataMeta.from }} tot {{ dataMeta.to }} van totaal {{
                            dataMeta.of
                          }} records zichtbaar</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                          cols="12"
                          sm="6"
                          class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalProlongations"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                          >
                            <template #prev-text>
                              <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                              />
                            </template>
                            <template #next-text>
                              <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                              />
                            </template>
                          </b-pagination>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </template>
              </b-col>
            </b-row>
          </template>
        </b-overlay>
      </b-tab>
      <b-tab
        ref="tabProlongated"
        title="Geprolongeerd"
        content-class="mt-1"
        show
        lazy
        @click="updateStatus('2')"
      >
        <b-overlay
          :show="showOverlay"
          rounded="sm"
        >
          <template>
            <b-row>
              <b-col
                cols="12"
                xl="12"
                lg="12"
                md="12"
              >
                <template>
                  <!-- Table Container Card -->
                  <b-card
                    no-body
                  >
                    <div class="m-2">
                      <!-- Table Top -->
                      <b-row>
                        <!-- Per Page -->
                        <b-col
                          cols="12"
                          md="6"
                          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                        >
                          <label>Entries</label>
                          <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block ml-50 mr-1"
                          />
                        </b-col>
                        <!-- Add -->
                        <b-col
                          class="d-flex align-items-center justify-content-end"
                          cols="12"
                          md="6"
                        />
                      </b-row>
                    </div>
                    <b-table
                      ref="refProlongationsListTable"
                      :items="fetchProlongations"
                      responsive
                      :fields="tableColumnsProlongated"
                      primary-key="id"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="isSortDirDesc"
                      show-empty
                      empty-text="Geen overeenkomende records gevonden"
                      class="position-relative prolongations-table"
                    >
                      <!-- Column: Id -->
                      <template #cell(id)="data">
                        #{{ data.item.id }}
                      </template>
                      <template #cell(orderID)="data">
                        <b-link
                          :id="`order-detail-view-action-row-${data.item.id}`"
                          :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
                        >
                          #{{ data.value }}
                        </b-link>
                      </template>
                      <template #cell(product)="data">
                        <div vertical-align="center">
                          <span class="font-weight-bold d-block text-nowrap">
                            {{ data.item.order.product.name }}
                          </span>
                          <small class="text-muted">{{ formatDateTime(data.item.order.timestamp) }}</small>
                        </div>
                      </template>
                      <template #cell(company)="data">
                        <div vertical-align="center">
                          <span class="font-weight-bold d-block text-nowrap">
                            {{ data.item.order.user.company.name }}
                          </span>
                          <small class="text-muted">{{ data.item.order.user.fullname }}</small>
                        </div>
                      </template>
                      <template #cell(premium)="data">
                        {{ data.value }} {{ data.item.order.currency }}
                      </template>
                      <template #cell(insuredAmount)="data">
                        {{ data.value }} {{ data.item.order.currency }}
                      </template>
                      <template #cell(timestamp)="data">
                        <div vertical-align="center">
                          <span class="font-weight-bold d-block text-nowrap">
                            {{ formatDateTime(data.value) }}
                          </span>
                          <small class="text-muted">Jaar {{ data.item.yearFrom }} tot {{ data.item.yearTo }}</small>
                        </div>
                      </template>
                    </b-table>
                    <div class="mx-2 mb-2">
                      <b-row>

                        <b-col
                          cols="12"
                          sm="6"
                          class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                          <span class="text-muted">Records {{ dataMeta.from }} tot {{ dataMeta.to }} van totaal {{
                            dataMeta.of
                          }} records zichtbaar</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                          cols="12"
                          sm="6"
                          class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalProlongations"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                          >
                            <template #prev-text>
                              <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                              />
                            </template>
                            <template #next-text>
                              <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                              />
                            </template>
                          </b-pagination>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </template>
              </b-col>
            </b-row>
          </template>
        </b-overlay>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BLink,
  BTooltip,
  BOverlay,
  BFormInvalidFeedback,
  BFormGroup,
  BForm,
  BFormInput,
  BButton,
  BPagination,
  BSpinner,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import prolongationsStoreModule from '@/views/pages/prolongations/prolongationsStoreModule'
import useProlongationsList from '@/views/pages/prolongations/useProlongationsList'
import { formatDateTime } from '@core/utils/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { decimal, required } from '@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BLink,
    vSelect,
    BTooltip,
    BOverlay,
    BFormInvalidFeedback,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    BPagination,
    BSpinner,
    BTabs,
    BTab,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      insuredAmount: null,
      prolongationsInsuredAmountSpinner: false,
      selectedId: null,
      tabIndex: 0,
    }
  },
  created() {
    if (String(this.$route.params.id) === '2') {
      this.tabIndex = 1
      this.sortByStatus = 2
    }

    if (!this.$route.params.id || String(this.$route.params.id) === '1') {
      this.tabIndex = 0
      this.sortByStatus = 1
    }
  },
  methods: {
    fetchStatistics() {
      store
        .dispatch('app-menu-statistics/fetchMenuStatistics')
        .then(response => {
          this.$store.commit('app/UPDATE_MENU_STATISTICS', response.data)
        })
    },
    getFullName(data) {
      let fullName = ''

      if (data) {
        if (data.middleName && data.middleName.length > 0) {
          fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
        } else {
          fullName = `${data.firstName} ${data.lastName}`
        }
      }

      return fullName
    },
    openProlongationsInsuredAmountModal(id) {
      this.selectedId = id
      this.$refs.prolongationsInsuredAmount.show()
    },
    setProlongationsInsuredAmount() {
      this.prolongationsInsuredAmountSpinner = true
      store.dispatch('app-prolongations/updateProlongation', { insuredAmount: this.insuredAmount, id: this.selectedId })
        .then(() => {
          this.fetchStatistics()
          this.prolongationsInsuredAmountSpinner = false
          this.$refs.prolongationsInsuredAmount.hide()
          this.refetchProlongationsData()
        })
        .catch(() => {
          this.prolongationsInsuredAmountSpinner = false
          this.$refs.prolongationsInsuredAmount.hide()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Prolongatie',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${errorAndSuccesDefaultMessage.updateDataError}`,
            },
          })
        })
    },
    updateStatus(status) {
      if (String(this.$route.params.id) !== status) {
        this.$router.replace({ path: `/order/prolongaties/${status}` })
      }
      this.sortByStatus = status
    },
  },
  setup() {
    const PROLONGATIONS_APP_STORE_MODULE_NAME = 'app-prolongations'

    // Register module
    if (!store.hasModule(PROLONGATIONS_APP_STORE_MODULE_NAME)) store.registerModule(PROLONGATIONS_APP_STORE_MODULE_NAME, prolongationsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROLONGATIONS_APP_STORE_MODULE_NAME)) store.unregisterModule(PROLONGATIONS_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})

    const {
      fetchProlongations,
      tableColumns,
      perPage,
      currentPage,
      totalProlongations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProlongationsListTable,
      refetchProlongationsData,
      showOverlay,
      sortByStatus,
      tableColumnsProlongated,
    } = useProlongationsList()

    return {
      fetchProlongations,
      tableColumns,
      perPage,
      currentPage,
      totalProlongations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProlongationsListTable,
      refetchProlongationsData,
      showOverlay,
      refFormObserver,
      getValidationState,
      sortByStatus,
      tableColumnsProlongated,
      required,
      decimal,
      formatDateTime,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }

  .categorie-table{
    .description{
      width:30%;
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
