import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProlongationsList() {
  // Use toast
  const toast = useToast()

  const refProlongationsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: '#', key: 'id', sortable: false },
    { label: 'Order ID', key: 'orderID', sortable: false },
    { label: 'Product', key: 'product', sortable: true },
    { label: 'Organisatie', key: 'company', sortable: true },
    { label: 'Premie', key: 'premium', sortable: true },
    { label: 'Verzekerd bedrag', key: 'insuredAmount', sortable: true },
    { label: 'Datum', key: 'timestamp', sortable: true },
    { label: 'Acties', key: 'actions', sortable: false },
  ]
  const tableColumnsProlongated = [
    { label: '#', key: 'id', sortable: false },
    { label: 'Order ID', key: 'orderID', sortable: false },
    { label: 'Product', key: 'product', sortable: true },
    { label: 'Organisatie', key: 'company', sortable: true },
    { label: 'Premie', key: 'premium', sortable: true },
    { label: 'Verzekerd bedrag', key: 'insuredAmount', sortable: true },
    { label: 'Datum', key: 'timestamp', sortable: true },
  ]
  const perPage = ref(10)
  const totalProlongations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const sortByStatus = ref(2)
  const isSortDirDesc = ref(false)
  const showOverlay = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refProlongationsListTable.value ? refProlongationsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProlongations.value,
    }
  })

  const refetchProlongationsData = () => {
    refProlongationsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchProlongationsData()
  })

  const fetchProlongations = (ctx, callback) => {
    showOverlay.value = true
    store
      .dispatch('app-prolongations/fetchProlongations', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
        isFirst: 0,
        status: sortByStatus.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalProlongations.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de prolongations',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchProlongations,
    tableColumns,
    perPage,
    currentPage,
    totalProlongations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProlongationsListTable,
    refetchProlongationsData,
    showOverlay,
    sortByStatus,
    tableColumnsProlongated,
  }
}
